/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Loading from "./loading"
import AOS from "aos" // eslint-disable-line
import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add( fas, fab )
dom.watch()

const Layout = ({ children }) => {
  const getData = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    AOS.init()
  })

  return (
    <div className="font-body sm:leading-relaxed">
      <Loading/>
      <Header siteTitle={getData.site.siteMetadata.title} />
      <div
        className="w-full max-w-screen-xl mx-auto px-3 md:px-8 md:pt-4 lg:pt-0 relative z-10"
      >
        {children}
        <footer
          className="py-4 text-center text-sm"
        >
          &copy; 2012-{new Date().getFullYear()}, Typetuto
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
