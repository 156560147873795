import React, { Component } from "react"
import { Link } from "gatsby"
import Axios from "axios"
import * as JsSearch from "js-search"

// https://www.gatsbyjs.org/docs/adding-search-with-js-search/
// https://ichinari.work/JavaScript_GatsbyJS_20191110
class Search extends Component {
  state = {
    bookList: [],      //JSONの内容を格納
    search: [],        //JsSearchインスタンスを格納
    searchResults: [], //検索結果を格納
    searchQuery: ''   //検索ワード
  }

  /**
   * 初期化 search.jsonを読み込む
   */
  async componentDidMount() {
    Axios.get('/search.json')
      .then(result => {
        const bookData = result
        this.setState({ bookList: bookData.data })
        this.rebuildIndex()
      })
      .catch(err => {
        this.setState({ isError: true })
      })
  }

  /**
   * 検索方法の設定
   */
  rebuildIndex = () => {
    const { bookList } = this.state;
    // JsSearchインスタンス作成（検索対象のリストでユニークとなるキーを指定する）
    const dataToSearch = new JsSearch.Search('slug');

    // 検索ワードをいい感じに変換する（とりあえずスペースで分割し、複数文字列で検索）
    dataToSearch.tokenizer = {
      tokenize( text ) {
        return text.split(/\s+/i);
      }
    };

    // 部分一致で検索する
    dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();

    // 検索ワードを小文字変換、trimする（記述しなくてもデフォルトで設定されている）
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();

    //検索方法の設定
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex('slug');

    // 検索を行うキー
    dataToSearch.addIndex('title');
    dataToSearch.addIndex('search');

    // 検索対象となるリストを設定
    dataToSearch.addDocuments(bookList);
    this.setState({ search: dataToSearch });
  }

  /**
   * 検索ワード変更時に検索を行う
   */
  searchData = e => {
    const { search } = this.state
    const queryResult = search.search(e.target.value);
    this.setState({ searchQuery: e.target.value, searchResults: queryResult })
  }

  render() {
    const { bookList, searchResults, searchQuery } = this.state
    const queryResults = searchQuery === '' ? bookList : searchResults
    return (
      <div className="relative">
        <div className="relative">
          <input
            id="search"
            className="py-1 pl-8 pr-2 bg-gray-200 w-40 sm:w-48 text-sm text-gray-700 focus:outline-none"
            placeholder="検索"
            aria-label="ワード検索"
            value={searchQuery}
            tabIndex="0"
            onChange={this.searchData}
          />
          <i className="fas fa-search absolute my-auto ml-2 inset-y-0 left-0 text-gray-700"
            style={{
              'width': `16px`
            }} />
        </div>
        <div
          className="absolute overflow-y-auto shadow rounded-b right-0 md:left-0 md:right-auto hover:bg-gray-200 focus:outline-none"
          style={{
            'width': `300px`,
            'maxHeight': `80vh`
          }}
        >
          {searchQuery && (queryResults.map(item => {
            return (
              <Link
                key={item.slug}
                to={item.slug}
                className="block border-t bg-white hover:bg-gray-100 p-2 text-xs text-gray-700"
                tabIndex="0"
              >
                <div className="flex">
                  <span className="inline-block pr-2">{item.title}</span>
                  <span
                    className={`category category-${item.category} px-1 rounded-sm ml-auto`}
                    style={{
                    }}
                  >
                    {item.category}
                  </span>
                </div>
              </Link>
            )
          }))}
        </div>
      </div>
    )
  }
}
export default Search