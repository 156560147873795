import React from "react"
import {Helmet} from "react-helmet";

export default class Loading extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    // this.setState({ loading: true })
  }

  render() {
    return (
      <>
        <Helmet>
          <style>
            {`
              #loading {
                position: fixed;
                z-index: 99;
                top: -5%;
                left: 0;
                width: 100%;
                height: 110%;
                background-color: #edf2f7;
                transition: opacity .3s;
              }

              #loading[data-loading="true"] {
                opacity: 0;
                pointer-events: none;
              }

              #loading .loading-text {
                position: fixed;
                top: 50%;
                left: 50%;
                text-align: center;
                transform: translate(-50%, -50%);
              }

              #loading .loading-text span:nth-child(1),
              #loading .loading-text span:nth-child(5) {
                animation: loading1 8s ease-in-out infinite alternate;
              }
              #loading .loading-text span:nth-child(2),
              #loading .loading-text span:nth-child(6),
              #loading .loading-text span:nth-child(8) {
                animation: loading2 3s ease-in-out 1s infinite alternate;
              }
              #loading .loading-text span:nth-child(3),
              #loading .loading-text span:nth-child(4),
              #loading .loading-text span:nth-child(7) {
                animation: loading3 5s ease-in-out -2s infinite alternate;
              }

              @keyframes loading1 {
                0% { margin: 0; }
                100% { margin: 0 .2em; }
              }
              @keyframes loading2 {
                0% { margin: 0; }
                100% { margin: 0 .1em; }
              }
              @keyframes loading3 {
                0% { margin: 0; }
                100% { margin: 0 .15em; }
              }
            `}
          </style>
        </Helmet>
        <div id="loading" data-loading={(this.state.loading)}>
          <div className="loading-text">
            <span>T</span>
            <span>y</span>
            <span>p</span>
            <span>e</span>
            <span>t</span>
            <span>u</span>
            <span>t</span>
            <span>o</span>
          </div>
        </div>
      </>
    )
  }
}
