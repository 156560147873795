import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import Search from "./search"
import Logo from "../assets/img/common/logo_typetuto.svg"

const Header = ({ siteTitle }) => {
  const getData = useStaticQuery(graphql`
    query categoryQuery {
      allJsonJson {
        edges {
          node {
            id
            category {
              name
              slug
              path
            }
          }
        }
      }
    }
  `)

  let categories = getData.allJsonJson.edges[0].node.category

  return (
    <header className="sm:fixed top-0 z-50 bg-white border-b border-gray-400 inset-x-0 px-4 lg:px-8 py-3 sm:py-2 flex items-center">
      <div className="w-full flex flex-wrap items-center justify-center lg:justify-between">
        <div className="flex w-full lg:w-auto items-center justify-between lg:flex-auto lg:pr-2">
          <Link to="/"
            className="block"
          >
            <img
              src={Logo}
              alt={siteTitle}
              className="h-10 w-auto"
              style={{
                "width": `100px`,
              }}
            />
          </Link>
          <Search />
        </div>

        <nav className="flex flex-wrap sm:flex-no-wrap justify-center mt-0 sm:mt-2 lg:mt-0 lg:-mr-4">
          {categories.map((item, id) =>
            <Link
              key={id}
              to={ item.path
                ? ( item.path )
                : ( `/${item.slug}` )
              }
              className="mx-4 mt-4 sm:mt-0 text-center"
            >
              <span className="capitalize">{item.slug}</span>
              <small
                className="block"
                style={{
                  "fontSize": `.5rem`,
                }}
              >{item.name}</small>
            </Link>
          )}
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
